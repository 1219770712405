<script setup>
import {
  JContentGrid,
  JContentGridSceleton,
  JPertaciImage,
  JImageSceleton,
  JFilmStrip,
} from 'media-flow-ui'

import { toRefs, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'

import { usePageBuilder } from '~/stores/page-builder'

import Card from '~~/models/layout/components/content/Card'
import TabData from '~~/models/layout/tabs/TabData'
import { $$t } from '~~/utils/i19m'

const pageBuilderStore = usePageBuilder()
const { deviceType } = storeToRefs(pageBuilderStore)

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  load: {
    type: Boolean,
    default: true,
    required: false,
  },
  rowOrder: {
    type: Number,
    required: true,
  },
})

const showSceleton = ref(true)

const isLoading = ref(true)
const widgetData = ref([])
const { data } = toRefs(props)
const numberOfColumns = ref(6)
const mediaNumber = ref(0)

const defaultSort = ref(null)

const linkData = ref(data.value.props?.linkData || data.value.linkData)

const displayOnlyLiveVideosTemp = ref(
  data.value.props?.mediaPropertyFilters?.toString() === [1].toString()
)

const preload = ref(
  data.value.props.isPreload || data.value.props.isPreload || false
)

//to change response
numberOfColumns.value =
  data.value?.columnsNumber || data.value?.props?.columnsNumber
const isClickable = computed(
  () =>
    data.value?.props?.card?.cards?.[deviceType.value]?.props?.clickable ?? true
)
const formattedData = ref(props.data)

if (data.value.props?.query?.id) {
  const tabData = new TabData()
  //set layout data foreach tab static ore tab dynamic(tabs)
  tabData.setTabLayout(data.value)

  formattedData.value = tabData
}

const hasPagination = formattedData.value.props.infiniteScroll

const getImageByAspectRatio =
  formattedData.value?.cardStyle &&
  formattedData.value?.cardStyle[deviceType.value]
    ? formattedData.value?.cardStyle[deviceType.value].imageStyle
        ?.aspectRatioProperty
    : null

const isStatic = formattedData.value?.cardStyle?.[deviceType.value]?.isStatic
const isFilmstrip =
  formattedData.value?.cardStyle?.[deviceType.value]?.isFilmstrip

const skip = ref(0)
const take = ref(12) //ref(formattedData.value.mediaNumber);
const total = ref(null)

const hasAiHub = ref(data.value.props.hasAiHub)

mediaNumber.value = data.value?.props?.mediaNumber
numberOfColumns.value = data.value.props.columnsNumber

const queryId = formattedData.value.props.queryId
const contentId = formattedData.value.contentId
const sortingMethod = ref(data.value.props?.sortingMethod ?? null)
const sortingMethods = ref(data.value.props?.sortingMethods ?? [])
const parentGroupId = ref(null)

sortingMethods.value = sortingMethods.value.map((method) => {
  method.name = $$t(method.name)
  return method
})

const loadMoreButtonData = computed(
  () => formattedData.value?.components?.loadMoreButton || null
)

await useAsyncData(async () => {
  parentGroupId.value =
    data.value?.props?.card?.cards?.[deviceType.value]?.props?.parentGroupId ||
    data.value?.props?.card?.parentGroupId ||
    data.value?.cardStyle?.[deviceType.value]?.parentGroupId ||
    null

  if (formattedData.value?.props?.hasLoadMoreButton) {
    const tempTake =
      formattedData.value?.props?.columnsNumber *
      formattedData.value?.props?.loadMoreRowCount

    if (tempTake && !isNaN(tempTake)) take.value = tempTake
  }

  const params = {
    take: take.value,
    skip: skip.value,
  }

  if (sortingMethod.value) {
    defaultSort.value = sortingMethod.value

    params.defaultSort = defaultSort.value
    params.sort = sortingMethod.value
  }

  let response

  const res = await pageBuilderStore.actionGetQueryDataInitClient(
    queryId,
    params,
    contentId,
    hasAiHub.value,
    props.rowOrder
  )

  if (res) {
    response = res.response
  }

  if (response && response !== -1) {
    showSceleton.value = false
    isLoading.value = false
    total.value = response.total
    skip.value += take.value

    if (
      !total.value ||
      skip.value >= total.value ||
      (!hasPagination && skip.value >= mediaNumber.value)
    )
      formattedData.value.props.hasLoadMoreButton = false
  }

  widgetData.value = formatFetchData(response?.data)

  if (displayOnlyLiveVideosTemp.value) {
    widgetData.value = widgetData.value.filter((item) => {
      return item.isLive === true
    })
  }

  // return widgetData.value
})

async function fetchData() {
  const params = {
    take: take.value,
    skip: skip.value,
  }

  if (sortingMethod.value) {
    params.sort = sortingMethod.value

    if (defaultSort.value) {
      params.defaultSort = defaultSort.value
    }
  }

  let response

  try {
    response = await pageBuilderStore.actionGetQueryDataFromClient(
      queryId,
      params,
      contentId,
      hasAiHub.value,
      props.rowOrder
    )

    if (response) {
      skip.value += take.value
      total.value = response.total

      if (
        !total.value ||
        skip.value >= total.value ||
        (!hasPagination && skip.value >= mediaNumber.value)
      )
        formattedData.value.props.hasLoadMoreButton = false
    }
  } catch (error) {
    console.error(error)
  } finally {
    // isLoading.value = false
    return response
  }
}

async function loadDataBySort(sort) {
  sortingMethod.value = sort
  skip.value = 0
  isLoading.value = true

  let response = await fetchData()

  if (!response || response.length <= 0) return

  widgetData.value = formatFetchData(response.data)

  isLoading.value = false
  await updateGridDataWithWatchTime(response.data)
}

async function loadMoreGridData() {
  if (!total.value || skip.value >= total.value) return
  if (!hasPagination && skip.value >= mediaNumber.value) return

  let response
  try {
    response = await fetchData()

    if (!response || response.length <= 0) return

    widgetData.value.push(...formatFetchData(response.data))

    await updateGridDataWithWatchTime(response.data)
  } catch (error) {
    console.error(error)
  }
}

function formatFetchData(data) {
  if (!data) return []

  return data.map((rawItem) => {
    const card = new Card()
    card.fromQuery(
      { ...rawItem, parentGroupId: parentGroupId.value },
      getImageByAspectRatio,
      isStatic,
      isClickable.value
    )

    return card
  })
}

const updateGridDataWithWatchTime = async (data) => {
  const mediaIds = data.map((s) => s.id)

  const progressData = await pageBuilderStore.postGetWatchTimeByMediaIds(
    mediaIds
  )

  if (!progressData) return

  widgetData.value.map((media) => {
    const progress = progressData.find((p) => p.mediaId === media.id)

    if (progress && media.length > 0) {
      const progressValue = Math.min(
        100,
        Math.ceil((progress.watchTime / media.length) * 100)
      )
      media.progressBarValue = progressValue
    }
    return media
  })
}

onMounted(async () => {
  await updateGridDataWithWatchTime(widgetData.value)

  if (widgetData.value.length <= 0) {
    console.log(`Content Grid no data`)
  }
})
</script>

<template>
  <div class="tab-wraps-grid">
    <!-- preload && -->
    <!-- test -->

    <!-- <Head v-if="widgetData.length > 0 && !showSceleton">
      <Link v-for="(number, i) in 1" rel="preload" as="image" :href="widgetData[i]?.img" :key="i" />
    </Head> -->

    <JContentGrid
      v-show="widgetData.length > 0"
      :data="widgetData"
      ref="jGrid"
      :id="data.id"
      :widget-identifier="formattedData.contentId || formattedData.id"
      :load-more-callback="loadMoreGridData"
      :navigation-data="linkData"
      :title="formattedData?.title || formattedData.props?.title"
      :title-style="{ ...formattedData.titleStyle, marginBottom: '20px' }"
      :number-of-columns="numberOfColumns"
      :content-wrapper-style="formattedData.style"
      :card-style="
        formattedData.cardStyle ? formattedData.cardStyle[deviceType] : {}
      "
      :selected-filter="sortingMethod"
      :filter-options="sortingMethods ?? []"
      :filter-content-callback="loadDataBySort"
      :sort-by-text="'Třídit podle'"
      :breakpoint="deviceType"
      :show-button="formattedData?.props?.button"
      :icons="{
        style: formattedData?.generalStyle?.style?.iconSettings,
      }"
      :new-item-text="$$t('component_card_new_text')"
      :show-load-more-button="
        !!(formattedData?.props.hasLoadMoreButton && widgetData.length > 0)
      "
      :load-more-button-data="loadMoreButtonData"
      :show-infinite-scroll="data?.props?.infiniteScroll"
      class="loadMoreGridData"
    >
      <template #button>
        <Button
          v-if="data?.components?.button"
          :data="data.components.button"
          class="z-10"
          text-before-button
        />
      </template>

      <template #card-image="{ image, style, filmstrip, alt }">
        <JPertaciImage
          v-if="!isLoading"
          :imageSrc="image"
          :alt="alt"
          class="w-full"
          no-lazy-image
          :style="style"
        />
        <JImageSceleton v-else :style="style"></JImageSceleton>

        <JFilmStrip
          v-if="isFilmstrip"
          :src="filmstrip"
          :switch-time="0.5"
          :style="{ ...style, backgroundColor: 'unset' }"
        />
      </template>
    </JContentGrid>
  </div>
</template>

<style lang="scss">
.j-tab {
  .tab-wraps-grid {
    // min-height: 550px;
  }
}
</style>

<style scoped lang="scss">
:deep(.j-content-grid-wrapper) {
  background-color: transparent !important;
  .j-content-grid > div:last-child {
    grid-column: 1 / -1;
    display: flex;
  }
}
</style>
